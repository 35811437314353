import type { AssignmentChatBase } from "@artesa/shared";
import { AssignmentChat } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";
import type { Service } from "@feathersjs/feathers";

declare module "../../feathers.client" {
  interface Services {
    "assignment-chats": Service<AssignmentChatBase>;
  }
}

export const useAssignmentChatStore = defineFeathersPiniaStore({
  schema: AssignmentChat,
  servicePath: "assignment-chats",
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useAssignmentChatStore;
