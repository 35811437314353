import { createI18n } from "vue-i18n";
import de from "../locales/de.json";
import { registerMessageCompiler, compile } from "@intlify/core-base";

// Type-define 'en-US' as the master schema for the resource
type MessageSchema = typeof de;

type CreateI18nInstanceOptions = {
  /**
   * @default false
   */
  globalInjection?: boolean;
  /**
   * @default: false
   */
  legacy?: boolean;
  /**
   * @default: false
   */
  forceMessageCompiler?: boolean;
};

export function createI18nInstance({
  globalInjection = false,
  legacy = false,
  forceMessageCompiler = false,
}: CreateI18nInstanceOptions = {}) {
  // TODO: We can improve this at some point
  const files = import.meta.glob("../locales/*.json");

  const messages = {};

  // Stupid workaround for ServiceWorkers
  if (forceMessageCompiler) {
    registerMessageCompiler(compile);
  }

  return createI18n<[MessageSchema], "de">({
    locale: import.meta.env.VITE_I18N_LOCALE || "de",
    fallbackLocale: {
      default: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "de",
      en: ["de"],
      it: ["en", "de"],
    },
    globalInjection,
    messages: {
      de,
    },
    legacy,
  });
}

globalThis._i18n = createI18nInstance({
  legacy: true,
});

export default {
  install: async app => {
    app.use(createI18nInstance({ globalInjection: true }));

    return app;
  },
};
