import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export const useRouteViewName = () => {
  const router = useRouter();
  const { t, te } = useI18n();

  function getRouteViewName(name: MaybeRefOrGetter<string | symbol>): string {
    const viewName = toValue(name);

    if (typeof viewName === "symbol") {
      return viewName.description ?? "";
    }

    const translateKey = `view.${viewName}`;

    if (te(translateKey)) {
      return t(translateKey);
    }

    try {
      const route = router.resolve({ name: viewName });

      return (route?.meta?.name as string) || viewName;
    } catch {
      return viewName;
    }
  }

  return {
    getRouteViewName,
  };
};
