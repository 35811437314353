import { useI18n } from "vue-i18n";
import { whenever } from "@vueuse/core";
import { setDefaultOptions } from "date-fns";

// Set default local
import de from "element-plus/es/locale/lang/de";
import dayjs from "dayjs";
import dayjsDeLocale from "dayjs/locale/de";
import type { Language } from "@artesa/shared";
import type { Language as ElementPlusLanguage } from "element-plus/lib/locale";

type FileImport = () => Promise<any>;

export function useLanguageSwitch() {
  const selectedLocal = useLocale();
  const i18n = useI18n();

  const elementPlusLocale = shallowRef<ElementPlusLanguage>(de);
  const dayJsLocale = shallowRef(dayjsDeLocale);
  dayjs.locale(dayjsDeLocale);

  const dayJsLocales = {
    en: () => import("dayjs/locale/en"),
    de: () => import("dayjs/locale/de"),
    it: () => import("dayjs/locale/it"),
  } satisfies Record<Language, FileImport>;
  const elementPlusLocales = {
    en: () => import("element-plus/es/locale/lang/en"),
    de: () => import("element-plus/es/locale/lang/de"),
    it: () => import("element-plus/es/locale/lang/it")
  } satisfies Record<Language, FileImport>;
  const locales = {
    en: () => import("@/locales/en.json"),
    de: () => import("@/locales/de.json"),
    it: () => import("@/locales/it.json"),
  } satisfies Record<Language, FileImport>;
  const dateFnsLocales = {
    en: () => import("date-fns/locale/en-US"),
    de: () => import("date-fns/locale/de"),
    it: () => import("date-fns/locale/it"),
  } satisfies Record<Language, FileImport>;

  whenever(
    () => i18n.locale.value !== selectedLocal.value,
    async () => {
      const [newDayJsLocale, elementPlusLocal, i18nLocale, dateFnsLocale] = await Promise.all([
        dayJsLocales[selectedLocal.value]().then(x => x.default),
        elementPlusLocales[selectedLocal.value]().then(x => x.default),
        locales[selectedLocal.value]().then(x => x.default),
        dateFnsLocales[selectedLocal.value]().then(x => x.default),
      ]);

      dayjs.locale(newDayJsLocale);

      dayJsLocale.value = newDayJsLocale;
      elementPlusLocale.value = elementPlusLocal;

      i18n.setLocaleMessage(selectedLocal.value, i18nLocale);
      i18n.locale.value = selectedLocal.value;
      i18n.fallbackLocale.value = "de";

      setDefaultOptions({
        locale: dateFnsLocale,
      });
    },
    { immediate: true },
  );

  return {
    elementPlusLocale,
    dayJsLocale,
  };
}
