import { Notification } from "./notifications.schema.js";
import { UserSettingXNotificationSchema } from "./user-settings-x-notifications.js";
export const NOTIFICATION_METHODS = [
  "find",
  "get",
  "create",
  "update",
  "patch",
  "remove",
  "getApplicationServerKey"
];
export const notificationTypeValues = Notification.anyOf.map(
  (schema) => schema.properties.type.const
);
export const notificationFilterValues = Object.keys(
  UserSettingXNotificationSchema.properties
);
export const notificationFilterMap = {
  ABSENCE_APPROVED: "absences",
  ABSENCE_COMPENSATION_APPROVED: "absences",
  ABSENCE_COMPENSATION_DECLINED: "absences",
  ABSENCE_DECLINED: "absences",
  ABSENCE_REQUIRES_APPROVE: "absences",
  ABSENCE_REQUIRES_COMPENSATION: "absences",
  ASSIGNMENT_ASSIGN: "assignments",
  ASSIGNMENT_STARTSAT_CHANGED: "assignments",
  ASSIGNMENT_UNASSIGN: "assignments",
  TIME_SHEET_SUBMITTED: "timeSheetSubmitted",
  TIME_SHEET_SUBMITTED_WEEK: "timeSheetSubmitted",
  TIME_SHEET_MONTH_SUBMITTED: "timeSheetSubmitted",
  TIME_SHEET_APPROVED: "timeSheetApproved",
  TIME_SHEET_APPROVED_WEEK: "timeSheetApproved",
  TIME_SHEET_MONTH_APPROVED: "timeSheetApproved",
  TIME_SHEET_DECLINED: "timeSheetDeclined",
  TIME_SHEET_DECLINED_WEEK: "timeSheetDeclined",
  TIME_SHEET_MONTH_DECLINED: "timeSheetDeclined",
  ASSIGNMENT_MESSAGE_RECEIVED: "assignmentMessage"
};
