import { Nullable, defineSchema, Type } from "@artesa/model-compiler";
import { compositeNotification } from "./notifications.utils.js";
export const Notification = defineSchema(
  "notifications",
  compositeNotification([
    (n) => n("ABSENCE_APPROVED", {
      absenceId: Type.Integer({ minimum: 1 })
    }),
    (n) => n("ABSENCE_COMPENSATION_APPROVED", {
      absenceId: Type.Integer({ minimum: 1 })
    }),
    (n) => n("ABSENCE_COMPENSATION_DECLINED", {
      absenceId: Type.Integer({ minimum: 1 })
    }),
    (n) => n("ABSENCE_DECLINED", {
      absenceId: Type.Integer({ minimum: 1 })
    }),
    (n) => n("ABSENCE_REQUIRES_APPROVE", {
      absenceId: Type.Integer({ minimum: 1 }),
      userId: Type.Integer({ minimum: 1 })
    }),
    (n) => n("ABSENCE_REQUIRES_COMPENSATION", {
      absenceId: Type.Integer({ minimum: 1 }),
      userId: Type.Integer({ minimum: 1 })
    }),
    (n) => n("ASSIGNMENT_ASSIGN", {
      companyId: Type.Integer({ minimum: 1 }),
      eventId: Type.Integer({ minimum: 1 }),
      id: Type.Integer({ minimum: 1 }),
      order: Type.Integer({ minimum: 1 }),
      userId: Type.Integer({ minimum: 1 }),
      createdAt: Type.Date(),
      updatedAt: Type.Date()
    }),
    (n) => n("ASSIGNMENT_STARTSAT_CHANGED", {
      companyId: Type.Integer({ minimum: 1 }),
      eventId: Type.Integer({ minimum: 1 }),
      order: Type.Integer({ minimum: 1 }),
      oldStartsAt: Type.Optional(Type.Date()),
      startsAt: Type.Optional(Type.Date()),
      createdAt: Type.Date(),
      updatedAt: Type.Date()
    }),
    (n) => n("ASSIGNMENT_UNASSIGN", {
      companyId: Type.Integer({ minimum: 1 }),
      eventId: Type.Integer({ minimum: 1 }),
      id: Type.Integer({ minimum: 1 }),
      userId: Type.Integer({ minimum: 1 }),
      order: Type.Integer({ minimum: 1 }),
      createdAt: Type.Date(),
      updatedAt: Type.Date()
    }),
    (n) => n("TIME_SHEET_APPROVED", {
      userId: Type.Integer({ minimum: 1 }),
      timeSheetId: Type.Integer({ minimum: 1 }),
      approvedAt: Type.Date(),
      approvedBy: Type.Integer({ minimum: 1 })
    }),
    (n) => n("TIME_SHEET_APPROVED_WEEK", {
      userId: Type.Integer({ minimum: 1 }),
      week: Type.Date(),
      approvedAt: Type.Date(),
      approvedBy: Type.Integer({ minimum: 1 })
    }),
    (n) => n("TIME_SHEET_DECLINED", {
      userId: Type.Integer({ minimum: 1 }),
      timeSheetId: Type.Integer({ minimum: 1 }),
      declinedAt: Type.Date(),
      declinedBy: Type.Integer({ minimum: 1 })
    }),
    (n) => n("TIME_SHEET_DECLINED_WEEK", {
      userId: Type.Integer({ minimum: 1 }),
      week: Type.Date(),
      declinedAt: Type.Date(),
      declinedBy: Type.Integer({ minimum: 1 })
    }),
    (n) => n("TIME_SHEET_SUBMITTED", {
      userId: Type.Integer({ minimum: 1 }),
      timeSheetId: Type.Integer({ minimum: 1 }),
      submittedAt: Type.Date()
    }),
    (n) => n("TIME_SHEET_SUBMITTED_WEEK", {
      userId: Type.Integer({ minimum: 1 }),
      week: Type.Date(),
      submittedAt: Type.Date()
    }),
    (n) => n("TIME_SHEET_MONTH_SUBMITTED", {
      userId: Type.Integer({ minimum: 1 }),
      timeSheetMonthId: Type.Integer({ minimum: 1 }),
      submittedAt: Type.Date()
    }),
    (n) => n("TIME_SHEET_MONTH_APPROVED", {
      userId: Type.Integer({ minimum: 1 }),
      timeSheetMonthId: Type.Integer({ minimum: 1 }),
      approvedAt: Type.Date(),
      approvedBy: Type.Integer({ minimum: 1 })
    }),
    (n) => n("TIME_SHEET_MONTH_DECLINED", {
      userId: Type.Integer({ minimum: 1 }),
      timeSheetMonthId: Type.Integer({ minimum: 1 }),
      declinedAt: Type.Date(),
      declinedBy: Type.Integer({ minimum: 1 }),
      declinedNote: Nullable(Type.String())
    }),
    (n) => n("ASSIGNMENT_MESSAGE_RECEIVED", {
      assignmentUserChat: Type.Integer({ minimum: 1 }),
      assignmentMessage: Type.Integer({ minimum: 1 }),
      assignmentChat: Type.Integer({ minimum: 1 })
    })
  ]),
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      forUser: r.belongsTo("users", { nullable: true })
    })
  }
);
