import type { AddressBase } from "@artesa/shared";
import { Address } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";
import type { Service } from "@feathersjs/feathers";

declare module "../../feathers.client" {
  interface Services {
    "addresses": Service<AddressBase>;
  }
}

export const useAddressStore = defineFeathersPiniaStore({
  schema: Address,
  servicePath: "addresses",
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useAddressStore;
