import { Type, StringEnum } from "../typebox/index.js";
import { uiScope } from "./custom-forms-shared.js";
import { printSettings } from "./print-settings.schema.js";
export * from "./custom-forms-shared.js";
export * from "./json-schema-type.js";
const tableElements = [
  Type.Ref("TextInput"),
  Type.Ref("BooleanInput"),
  Type.Ref("RadioInput"),
  Type.Ref("Text"),
  Type.Ref("HTML"),
  Type.Ref("NumberInput"),
  Type.Ref("DateInput"),
  Type.Ref("TimeInput"),
  Type.Ref("DurationInput"),
  Type.Ref("DateTimeInput"),
  Type.Ref("SelectInput"),
  Type.Ref("DropdownButtonInput"),
  Type.Ref("ButtonInput"),
  Type.Ref("SignatureInput"),
  Type.Ref("DrawInput"),
  Type.Ref("FileInput"),
  Type.Ref("Image"),
  Type.Ref("SelectUser"),
  Type.Ref("SelectAssignmentType"),
  Type.Ref("SelectProject"),
  Type.Ref("SelectAssignment"),
  Type.Ref("SelectAssignmentEventType"),
  Type.Ref("SelectCustomer"),
  Type.Ref("CardCustomer"),
  Type.Ref("CardAddress")
];
const allElements = [
  ...tableElements,
  Type.Ref("ListInput"),
  Type.Ref("TableInput"),
  Type.Ref("Container")
];
const commons = Type.Object({
  comment: Type.Optional(Type.String()),
  class: Type.Optional(Type.String()),
  style: Type.Optional(Type.String()),
  containerClass: Type.Optional(Type.String()),
  containerStyle: Type.Optional(Type.String()),
  title: Type.Optional(Type.String()),
  description: Type.Optional(Type.String()),
  labelClass: Type.Optional(Type.String()),
  labelStyle: Type.Optional(Type.String()),
  labelWidth: Type.Optional(Type.String()),
  hide: Type.Optional(Type.String()),
  show: Type.Optional(Type.String())
});
const $defs = {
  TextInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("TextInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            resize: Type.Optional(Type.Boolean()),
            prefix: Type.Optional(Type.String()),
            suffix: Type.Optional(Type.String()),
            prepend: Type.Optional(Type.String()),
            append: Type.Optional(Type.String())
          })
        )
      })
    ]
  ),
  BooleanInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("BooleanInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            text: Type.Optional(Type.String()),
            switch: Type.Optional(
              Type.Object({
                activeText: Type.Optional(Type.String()),
                inactiveText: Type.Optional(Type.String())
              })
            ),
            checkbox: Type.Optional(
              Type.Object({
                activeText: Type.Optional(Type.String()),
                inactiveText: Type.Optional(Type.String())
              })
            )
          })
        )
      })
    ]
  ),
  RadioInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("RadioInput"),
        scope: uiScope,
        options: Type.Object({
          options: Type.Array(
            Type.Object({
              label: Type.String(),
              value: Type.Any()
            })
          ),
          size: Type.Optional(StringEnum(["small", "default", "large"])),
          direction: Type.Optional(StringEnum(["horizontal", "vertical"]))
        })
      })
    ]
  ),
  Text: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("Text"),
        scope: Type.Optional(Type.String()),
        value: Type.String(),
        tag: Type.Optional(StringEnum(["p", "h1", "h2", "h3", "h4"]))
      })
    ]
  ),
  HTML: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("HTML"),
        scope: Type.Optional(Type.String()),
        html: Type.String()
      })
    ]
  ),
  NumberInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("NumberInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            min: Type.Optional(Type.Number()),
            max: Type.Optional(Type.Number()),
            step: Type.Optional(Type.Number()),
            stepStrictly: Type.Optional(Type.Boolean()),
            precision: Type.Optional(Type.Number()),
            controls: Type.Optional(Type.Union([Type.Literal("right"), Type.Boolean()])),
            prefix: Type.Optional(Type.String()),
            suffix: Type.Optional(Type.String())
          })
        )
      })
    ]
  ),
  DateInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("DateInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            format: Type.Optional(Type.String()),
            formatMinimum: Type.Optional(Type.String()),
            formatMaximum: Type.Optional(Type.String()),
            formatExclusiveMinimum: Type.Optional(Type.String()),
            formatExclusiveMaximum: Type.Optional(Type.String())
          })
        )
      })
    ]
  ),
  TimeInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("TimeInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            valueType: Type.Optional(
              Type.Union([
                Type.Literal("number"),
                Type.Literal("date"),
                Type.Literal("time"),
                Type.Literal("hours")
              ])
            ),
            placeholder: Type.Optional(Type.String())
          })
        )
      })
    ]
  ),
  DurationInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("DurationInput"),
        scope: uiScope,
        options: Type.Optional(Type.Object({}))
      })
    ]
  ),
  DateTimeInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("DateTimeInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            defaultDate: Type.Optional(Type.String()),
            defaultTime: Type.Optional(Type.String()),
            dateDisabled: Type.Optional(Type.Union([Type.String(), Type.Boolean()])),
            timeDisabled: Type.Optional(Type.Union([Type.String(), Type.Boolean()])),
            showButtonToday: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  SelectInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SelectInput"),
        scope: uiScope,
        options: Type.Object({
          options: Type.Array(
            Type.Union([
              Type.String(),
              Type.Object({
                label: Type.String(),
                value: Type.Union([Type.String(), Type.Record(Type.String(), Type.Any())]),
                class: Type.Optional(Type.String()),
                style: Type.Optional(Type.String())
              })
            ])
          ),
          allowCreate: Type.Optional(Type.Boolean()),
          multi: Type.Optional(Type.Boolean()),
          valueKey: Type.Optional(Type.String()),
          clearable: Type.Optional(Type.Boolean()),
          placeholder: Type.Optional(Type.String())
        })
      })
    ]
  ),
  DropdownButtonInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("DropdownButtonInput"),
        scope: uiScope,
        options: Type.Object({
          options: Type.Array(
            Type.Union([
              Type.String(),
              Type.Object({
                label: Type.String(),
                value: Type.Union([Type.String(), Type.Record(Type.String(), Type.Any())])
              })
            ])
          ),
          multi: Type.Optional(Type.Boolean()),
          allowItemMultiple: Type.Optional(Type.Boolean()),
          trigger: Type.Optional(StringEnum(["hover", "click", "contextmenu"])),
          hideOnClick: Type.Optional(Type.Boolean()),
          button: Type.Optional(
            Type.Object({
              label: Type.Optional(Type.String()),
              type: Type.Optional(
                StringEnum(["primary", "success", "info", "warning", "danger"])
              ),
              plain: Type.Optional(Type.Boolean()),
              round: Type.Optional(Type.Boolean()),
              circle: Type.Optional(Type.Boolean()),
              text: Type.Optional(Type.Boolean())
            })
          )
        })
      })
    ]
  ),
  ButtonInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("ButtonInput"),
        scope: uiScope,
        options: Type.Object({
          label: Type.Optional(Type.String()),
          type: Type.Optional(
            StringEnum(["primary", "success", "info", "warning", "danger"])
          ),
          plain: Type.Optional(Type.Boolean()),
          round: Type.Optional(Type.Boolean()),
          circle: Type.Optional(Type.Boolean()),
          text: Type.Optional(Type.Boolean()),
          multi: Type.Optional(Type.Boolean()),
          value: Type.Any()
        })
      })
    ]
  ),
  SignatureInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SignatureInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            defaultName: Type.Optional(
              Type.Object({
                type: StringEnum(["customer", "user"]),
                scope: uiScope,
                default: Type.Optional(Type.String())
              })
            )
          })
        )
      })
    ]
  ),
  DrawInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("DrawInput"),
        scope: uiScope,
        title: Type.Optional(Type.String()),
        options: Type.Optional(
          Type.Object({
            fullscreenClass: Type.Optional(Type.String())
          })
        )
      })
    ]
  ),
  FileInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("FileInput"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            allowedMimeTypes: Type.Optional(Type.Array(Type.String())),
            imageResize: Type.Optional(
              Type.Object({
                width: Type.Optional(Type.Number()),
                height: Type.Optional(Type.Number()),
                quality: Type.Optional(Type.Number())
              })
            )
          })
        )
      })
    ]
  ),
  Image: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("Image"),
        value: Type.String()
      })
    ]
  ),
  SelectUser: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SelectUser"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            clearable: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  SelectAssignmentType: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SelectAssignmentType"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            clearable: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  SelectProject: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SelectProject"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            clearable: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  SelectAssignment: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SelectAssignment"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            clearable: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  SelectAssignmentEventType: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SelectAssignmentEventType"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            clearable: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  SelectCustomer: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("SelectCustomer"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            placeholder: Type.Optional(Type.String()),
            clearable: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  CardCustomer: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("CardCustomer"),
        scope: uiScope,
        options: Type.Optional(
          Type.Object({
            allowCreate: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  CardAddress: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("CardAddress"),
        scope: uiScope,
        options: Type.Optional(Type.Object({}))
      })
    ]
  ),
  TableInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("TableInput"),
        scope: uiScope,
        rows: Type.Array(
          Type.Object({
            element: Type.Union([...tableElements]),
            width: Type.Optional(Type.String()),
            title: Type.Optional(Type.String()),
            align: Type.Optional(StringEnum(["left", "center", "right"]))
          })
        ),
        options: Type.Optional(
          Type.Object({
            addable: Type.Optional(Type.Boolean()),
            removable: Type.Optional(Type.Boolean()),
            draggable: Type.Optional(Type.Boolean()),
            zeroEntriesText: Type.Optional(Type.Union([Type.String(), Type.Boolean()])),
            border: Type.Optional(Type.Boolean()),
            hideHeader: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  ListInput: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("ListInput"),
        scope: uiScope,
        element: Type.Union([...allElements]),
        options: Type.Optional(
          Type.Object({
            listType: Type.Optional(StringEnum(["unordered", "ordered"])),
            addable: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
            removable: Type.Optional(Type.Union([Type.Boolean(), Type.String()])),
            draggable: Type.Optional(Type.Boolean())
          })
        )
      })
    ]
  ),
  Container: Type.Composite(
    [
      commons,
      Type.Object({
        type: Type.Literal("Container"),
        layout: Type.Optional(StringEnum(["none", "row", "column"])),
        elements: Type.Array(Type.Union([...allElements])),
        options: Type.Optional(
          Type.Object({
            sizeTitle: Type.Optional(StringEnum(["sm", "md", "lg"]))
          })
        )
      })
    ]
  ),
  uiSchema: Type.Object({
    version: Type.Literal(1),
    class: Type.Optional(Type.String()),
    printSettings,
    variables: Type.Optional(Type.Record(Type.String(), Type.Any())),
    elements: Type.Array(Type.Union([...allElements]), {
      default: [{ type: "Container", class: "grid grid-cols-1 gap-3", elements: [] }]
    })
  }),
  Element: Type.Union([...allElements])
};
const Module = Type.Module($defs);
const getSchema = (name) => {
  const def = Module.Import(name);
  const schema = def.$defs[name];
  const isDef = (value) => {
    return value instanceof Object && "type" in value && value.type === name;
  };
  return [def, schema, isDef];
};
export const [uiTextInputDef, uiTextInput, isUiTextInput] = getSchema("TextInput");
export const [uiBooleanInputDef, uiBooleanInput, isUiBooleanInput] = getSchema("BooleanInput");
export const [uiRadioInputDef, uiRadioInput, isUiRadioInput] = getSchema("RadioInput");
export const [uiTextDef, uiText, isUiText] = getSchema("Text");
export const [uiHtmlDef, uiHtml, isUiHtml] = getSchema("HTML");
export const [uiNumberInputDef, uiNumberInput, isUiNumberInput] = getSchema("NumberInput");
export const [uiDateInputDef, uiDateInput, isUiDateInput] = getSchema("DateInput");
export const [uiTimeInputDef, uiTimeInput, isUiTimeInput] = getSchema("TimeInput");
export const [uiDurationInputDef, uiDurationInput, isUiDurationInput] = getSchema("DurationInput");
export const [uiDateTimeInputDef, uiDateTimeInput, isUiDateTimeInput] = getSchema("DateTimeInput");
export const [uiSelectInputDef, uiSelectInput, isUiSelectInput] = getSchema("SelectInput");
export const [uiDropdownButtonInputDef, uiDropdownButtonInput, isUiDropdownButtonInput] = getSchema("DropdownButtonInput");
export const [uiButtonInputDef, uiButtonInput, isUiButtonInput] = getSchema("ButtonInput");
export const signatureSchema = Type.Object({
  signature: Type.String(),
  timestamp: Type.String({ format: "date-time" }),
  name: Type.String()
});
export const [uiSignatureInputDef, uiSignatureInput, isUiSignatureInput] = getSchema("SignatureInput");
export const [uiDrawInputDef, uiDrawInput, isUiDrawInput] = getSchema("DrawInput");
export const [uiFileInputDef, uiFileInput, isUiFileInput] = getSchema("FileInput");
export const [uiImageDef, uiImage, isUiImage] = getSchema("Image");
export const [uiSelectUserInputDef, uiSelectUserInput, isUiSelectUserInput] = getSchema("SelectUser");
export const [uiSelectAssignmentTypeInputDef, uiSelectAssignmentTypeInput, isUiSelectAssignmentTypeInput] = getSchema("SelectAssignmentType");
export const [uiSelectProjectInputDef, uiSelectProjectInput, isUiSelectProjectInput] = getSchema("SelectProject");
export const [uiSelectAssignmentInputDef, uiSelectAssignmentInput, isUiSelectAssignmentInput] = getSchema("SelectAssignment");
export const [uiSelectAssignmentEventTypeInputDef, uiSelectAssignmentEventTypeInput, isUiSelectAssignmentEventTypeInput] = getSchema("SelectAssignmentEventType");
export const [uiSelectCustomerInputDef, uiSelectCustomerInput, isUiSelectCustomerInput] = getSchema("SelectCustomer");
export const [uiCardCustomerDef, uiCardCustomer, isUiCardCustomer] = getSchema("CardCustomer");
export const [uiCardAddressDef, uiCardAddress, isUiCardAddress] = getSchema("CardAddress");
export const [uiListInputDef, uiListInput, isUiListInput] = getSchema("ListInput");
export const [uiTableInputDef, uiTableInput, isUiTableInput] = getSchema("TableInput");
export const [uiContainerDef, uiContainer, isUiContainer] = getSchema("Container");
export const [uiElementDef, uiElement, isUiElement] = getSchema("Element");
export const [uiSchemaDef, uiSchema] = getSchema("uiSchema");
export function isUiSchema(value) {
  return value instanceof Object && "elements" in value && "version" in value;
}
export const formTemplateUiSchemas = Object.values(Module.Import("uiSchema").$defs);
export { printSettingMarginToRem } from "./print-settings.schema.js";
