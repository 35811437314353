import { feathers as generateApp } from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";

import io from "socket.io-client";
import auth, { MemoryStorage } from "@feathersjs/authentication-client";
import { calculateWorkingTimesServiceMethods, passwordResetTokensMethods } from "@artesa/shared";
import type { CalculateWorkingTimesServiceType, PasswordResetTokensServiceType } from "@artesa/shared";
import type {
  CustomerService,
  InvoiceService,
  SubscriptionService,
  SubscriptionItemService,
  PayoutService,
  BalanceTransactionService,
} from "feathers-stripe";
import hooks from "./feathers.hooks";
import { host, IdbStorage } from "./feathers.common";
import type feathers from "@feathersjs/feathers";
import type SocketIoType from "socket.io-client";

const socket: ReturnType<typeof SocketIoType> = io(host, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  reconnectionDelay: 5000,
});

/**
 * Raw service calls return string instead of Date
 */
export type DateToString<O> = {
  [Key in keyof O]: Date extends O[Key] ? Exclude<O[Key], Date> | string : DateToString<O[Key]>;
};

export interface Services {
  "calculate-working-times": CalculateWorkingTimesServiceType;
  "stripe/customers": CustomerService;
  "stripe/invoices": InvoiceService;
  "stripe/subscriptions": SubscriptionService;
  "stripe/subscription-items": SubscriptionItemService;
  "stripe/balance-transactions": BalanceTransactionService;
  "stripe/payouts": PayoutService;
  "maps/find-place-from-text": any;
  "password-reset-tokens": PasswordResetTokensServiceType;
  // TODO: leave this for now
  [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface Configuration {}

export type Application = feathers.Application<Services, Configuration>;

export const app = generateApp<Services, Configuration>();

app.hooks(hooks);

export const connection = socketio<Services>(socket);

app.configure(connection);

const useMemoryStorage =
  "location" in globalThis && new URL(globalThis.location.href).searchParams.has("temp_access");

app.configure(
  auth({
    storage: useMemoryStorage ? new MemoryStorage() : new IdbStorage(),
  }),
);

app.use("calculate-working-times", connection.service("calculate-working-times"), {
  methods: calculateWorkingTimesServiceMethods,
});

app.use("stripe/customers", connection.service("stripe/customers"), {
  methods: ["find", "search", "get"],
});

app.use("stripe/invoices", connection.service("stripe/invoices"), {
  methods: ["find", "search", "get"],
});

app.use("stripe/subscriptions", connection.service("stripe/subscriptions"), {
  methods: ["find", "get", "search"],
});

app.use("stripe/subscription-items", connection.service("stripe/subscription-items"), {
  methods: ["find", "get", "search"],
});

app.use("password-reset-tokens", connection.service("password-reset-tokens"), {
  methods: passwordResetTokensMethods,
});

export default app;

export { socket, host };
