export const languagesAvailable = ["de", "en", "it"];
export const languageDefault = "de";
export const COMPANY_METHODS = [
  "find",
  "get",
  "create",
  "update",
  "patch",
  "remove",
  "new"
];
