import { Nullable, Type } from "@artesa/model-compiler";
import { FullOptional } from "../../typebox/index.js";
export const NotificationTargetSelectionSchema = Type.Object({
  inApp: FullOptional(Type.Boolean()),
  push: FullOptional(Type.Boolean())
});
export const UserSettingXNotificationSchema = Type.Object({
  absences: FullOptional(NotificationTargetSelectionSchema),
  assignments: FullOptional(NotificationTargetSelectionSchema),
  deadlineChange: FullOptional(NotificationTargetSelectionSchema),
  timeSheetSubmitted: FullOptional(NotificationTargetSelectionSchema),
  timeSheetApproved: FullOptional(NotificationTargetSelectionSchema),
  timeSheetDeclined: FullOptional(NotificationTargetSelectionSchema),
  assignmentMessage: FullOptional(NotificationTargetSelectionSchema)
});
export const UserSettingsXWebPushSchema = Type.Object({
  endpoint: Type.String(),
  expirationTime: Nullable(
    Type.Number()
    /* DOMHighResTimeStamp */
  ),
  keys: Type.Object({
    p256dh: Type.String(),
    auth: Type.String()
  })
});
